export const defaultCategories = [
  'Пригоди',
  'Платформери',
  'Екшн',
  'Бійки',
  'Кооперативні',
  'Симулятори',
  'Головоломки',
  'Шутери',
  'Космос',
  'Рольові',
  'Сімейні',
  'Стратегії',
  'Аркади',
  'Спортивні',
  'Відкритий світ',
  'Гонки',
  'Музика',
  'Риболовля',
  'Дитячі',
  'Ретро',
  'Кіберпанк',
  'Жахи',
  'Настільні',
  'Детективи',
  'Виживання',
  'Для нього та для неї'
];

export const players = [
  '1',
  '1+',
  '1-16',
  '1-2',
  '1-24',
  '1-3',
  '1-32',
  '1-4',
  '1-5',
  '1-6',
  '1-8',
  '1-99',
  '2',
  '5-8'
];

export const years = [
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
];
