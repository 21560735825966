import './NotFound.scss';

export const NotFound = () => (

  
  <div id="main">
    <div className="fof">
      <h1 className='fof__title'>Помилка 404</h1>

      <h2>Упс... Такої сторінки не існує.</h2>
    </div>
  </div>
);
